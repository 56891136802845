import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import HomeDesktop from "./page/Home/HomeDesktop";
import ProjectDesktop from "./page/Project/ProjectDesktop";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import Test from './page/Project/Test'


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route>
        <Route index element={<App />} />
        <Route path="home" element={<HomeDesktop />} />
        <Route path="project" element={<ProjectDesktop />} />
        {/* <Route path="test" element={<Test/>} /> */}
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
